<template>
  <ClientOnly>
    <main class="flex-1 text-base">
      <Disclosure
          as="template"
          v-slot="{ open }"
          :default-open="footerMenu.length === 1"
          v-for="menu in footerMenu"
          :key="`ml-${menu.id}`"
      >
        <DisclosureButton
            class="text-left w-full flex justify-between items-start focus:outline-none py-3"
            v-if="menu.children.length > 0"
        >
        <span class="text-base font-bold uppercase">{{
            getLocaleString(menu.name)
          }}</span>
          <PlusIcon
              class="h-4 w-4 transform"
              aria-hidden="true"
              v-if="menu.length !== 1 && !open"
          />
          <MinusIcon
              class="h-4 w-4 transform"
              aria-hidden="true"
              v-if="menu.length !== 1 && open"
          />
        </DisclosureButton>

        <NuxtLinkLocale
            :href="getLocaleString(menu.href, true)"
            class="block focus:outline-none text-base font-medium cursor-pointer uppercase py-3"
            active-class="focus:outline-none text-secondary text-base underline cursor-pointer uppercase"
            v-else
        >
          {{ getLocaleString(menu.name) }}
        </NuxtLinkLocale>

        <DisclosurePanel as="div" class="font-normal">
          <Disclosure
              v-for="item in menu.children"
              as="template"
              :key="`mlc-${item.id}`"
              v-slot="{ open }"
          >
            <DisclosureButton
                class="text-left w-full flex justify-between items-start text-lg px-1.5 py-2.5"
            >
              <div
                  v-if="!getLocaleString(item.href)"
                  class="flex justify-between items-center"
              >
                <p
                    :id="`${getLocaleString(item.name)}-heading`"
                    class="ml-2 text-base"
                >
                  {{ getLocaleString(item.name) }}
                </p>
              </div>

              <div class="flex justify-between items-center" v-else>
                <NuxtLinkLocale
                    :href="getLocaleString(item.href, true)"
                    class="text-base cursor-pointer ml-2"
                >
                  {{ getLocaleString(item.name) }}
                </NuxtLinkLocale>
              </div>

              <div class="ml-6 flex items-center" v-if="item.children.length > 1">
                <PlusIcon
                    class="h-4 w-4 transform"
                    aria-hidden="true"
                    v-if="item.length !== 1 && !open"
                />
                <MinusIcon
                    class="h-4 w-4 transform"
                    aria-hidden="true"
                    v-if="item.length !== 1 && open"
                />
              </div>
            </DisclosureButton>

            <DisclosurePanel v-if="item.children.length > 1" as="div" class="p-3">
              <ul role="list" class="ml-4 grid gap-y-2">
                <li
                    v-for="i in item?.children"
                    :key="`scl-${i.id}`"
                    class="flex py-2"
                >
                  <NuxtLinkLocale
                      :href="getLocaleString(i.href, true)"
                      class="hover:text-gray-800 text-base"
                  >
                    {{ getLocaleString(i.name) }}
                  </NuxtLinkLocale>
                </li>
              </ul>
            </DisclosurePanel>
          </Disclosure>
        </DisclosurePanel>
      </Disclosure>
    </main>
  </ClientOnly>
</template>

<script setup>
import {
  DisclosureButton,
  Disclosure,
  DisclosurePanel
} from "@headlessui/vue";

import { PlusIcon, MinusIcon } from "@heroicons/vue/20/solid";

const { locale } = useI18n();

const storeConfig = useStoreConfig()
const { footerMenu, footerConfig } = storeToRefs(storeConfig)

</script>

<style scoped></style>
