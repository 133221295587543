<template>
  <div class="footer-copyright py-2 px-4" :data-nosnippet="true">
    <div class="max-w-screen-2xl mx-auto flex sm:flex-row flex-col justify-between items-center">
      <div class="w-full flex items-center my-2 sm:my-0">
        <a href="https://labsoffice.com" target="_blank" class="flex items-center pr-0.5" rel="noreferrer" aria-label="Labs Commerce">
          <LabsLogo class="fill-black h-8 w-8" />
        </a>
        <div class="pl-2 flex flex-col text-left">
          <div class="text-xs">{{t('designed-by-labs')}}</div>
          <div class="text-xs">Copyright© {{year}} <span class="font-normal">{{copyrightText}}</span>. All rights reserved.</div>
        </div>
      </div>
      <div class="flex items-center justify-center md:justify-end w-full my-4 sm:my-0">
        <PaymentLogoLine />
      </div>
    </div>
  </div>
</template>

<script setup>
import LabsLogo from "assets/images/labs-icon.svg";
const config = useRuntimeConfig();
const { isWholesaleUser } = await useCustomer();
const { t } = useI18n({
  useScope: 'local'
})
const d = new Date();
const year = ref(d.getFullYear());
const copyrightText = getLocaleString(config.public?.site_title);
</script>

<i18n lang="json">
{
  "tr": {
    "designed-by-labs" : "Bu web sitesi Labs e-ticaret çözümlerini kullanmaktadır."
  },
  "en": {
    "designed-by-labs" : "This website uses Labs e-commerce services."
  }
}
</i18n>